import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [3,4,5,6,7,8,9,10,11];

export const dictionary = {
		"/": [~13],
		"/api/render/offer/[id]": [~14,[2]],
		"/app": [15,[3]],
		"/app/calculation": [~16,[3]],
		"/app/calculation/[id]": [~17,[3,4]],
		"/app/calculation/[id]/edit": [~18,[3,4]],
		"/app/component-groups": [~22,[3]],
		"/app/component-groups/[id]": [~23,[3]],
		"/app/component-template": [~24,[3]],
		"/app/component-template/[id]": [~25,[3]],
		"/app/component-tree": [~26,[3]],
		"/app/component-tree/[id]": [~27,[3]],
		"/app/component": [~19,[3]],
		"/app/component/[id]": [~20,[3,5]],
		"/app/component/[id]/edit": [~21,[3,5]],
		"/app/country": [~28,[3]],
		"/app/country/[code]": [~29,[3,6]],
		"/app/country/[code]/edit": [~30,[3,6]],
		"/app/customer": [~31,[3]],
		"/app/customer/[id]": [~32,[3,7]],
		"/app/customer/[id]/edit": [~33,[3,7]],
		"/app/dashboard": [~34,[3]],
		"/app/inquiry": [~35,[3]],
		"/app/inquiry/[id]": [~36,[3,8]],
		"/app/inquiry/[id]/edit": [~37,[3,8]],
		"/app/offer-element-templates": [~40,[3]],
		"/app/offer-element-templates/[id]": [~41,[3]],
		"/app/offer": [~38,[3]],
		"/app/offer/[id]": [~39,[3]],
		"/app/plant": [~42,[3]],
		"/app/plant/[code]": [~43,[3,9]],
		"/app/plant/[code]/edit": [~44,[3,9]],
		"/app/steelprice": [45,[3,10]],
		"/app/steelprice/edit": [~46,[3,10]],
		"/app/unit": [~47,[3]],
		"/app/unit/[id]": [~48,[3,11]],
		"/app/unit/[id]/edit": [~49,[3,11]],
		"/app/user": [~50,[3]],
		"/app/user/[id]": [~51,[3]],
		"/auth": [~52,[12]],
		"/auth/forgot-password": [53,[12]],
		"/auth/signout": [54,[12]],
		"/auth/signup": [~55,[12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';